<template>
  <div class="about view-container">
    <MainCard
      :data="aboutData"
      class="main-card"/>
    <ProgramTab
      :list="aboutList"
      :selected-program="selectedProgram">
      <template slot="slot-0-0">
        <div class="originator">
          <img src="@/assets/image/about/originator.png" alt="originator">
          <p class="originator-name">{{ $t('孙千然') }}</p>
        </div>
      </template>
      <template slot="h5-slot-0-0">
        <div class="originator">
          <img src="@/assets/image/about/originator.png" alt="originator">
          <p class="originator-name">{{ $t('孙千然') }}</p>
        </div>
      </template>
    </ProgramTab>
  </div>
</template>

<script>
import image from '@/assets/image/about/main.png'
import h5Image from '@/assets/image/about/main-h5.png'
import image1 from '@/assets/image/about/item1.png'
import image2 from '@/assets/image/about/item2.png'
import image3 from '@/assets/image/about/item3.png'
import activeImage1 from '@/assets/image/about/active-item1.png'
import activeImage2 from '@/assets/image/about/active-item2.png'
import activeImage3 from '@/assets/image/about/active-item3.png'
export default {
  data () {
    return {
      aboutData: {
        title: '关于我们',
        subtitle: 'About Us',
        image,
        h5Image
      },
      aboutList: [
        {
          id: 'originator',
          image: image1,
          activeImage: activeImage1,
          text: '发起人',
          details: {
            title: '发起人',
            pList: [
              '把爱传下去的发起人为孙千然，孙千皓，孙炜雅。',
              '联合发起人：曹澜馨，张伽瑜，苏子洲。'
            ]
          }
        },
        {
          id: 'story',
          image: image2,
          activeImage: activeImage2,
          text: '我们的故事',
          details: {
            title: '我们的故事',
            pList: [
              '基于真实事件改编的奥斯卡得奖电影《把爱传下去》讲述了14岁的男孩特雷弗发起的「把爱传下去」运动。这个运动始于他的一次特殊的课后作业：「想一个改变世界的主意，并将其付诸行动。」特雷弗想到了那些需要帮助的人，他帮助三个真正需要帮助的人，然后受帮助的人再去帮助另外三个真正需要帮助的人，由此越来越多的人参与进来，越来越多的爱被传递，越来越多的人受到了帮助。有时候改变世界也很简单。',
              '2020年，孙千然、孙千皓及孙炜雅观看了这部名为《把爱传下去》后，与特雷弗年纪相仿的他们深受感触，联合曹澜馨，张伽瑜和苏子洲，决定一起发起一场「把爱传下去」的运动。经过细致调研与思考，他们认识到教育在人的发展过程中扮演着重要作用，并有感于乡村女童读书的困境，创立了「把爱传下去」同名公益助学项目。这便是本项目的由来。',
              '发起人们希望通过「把爱传下去」为学生们埋下一颗爱的种子，正像电影里讲述的一样，接受过「把爱传下去」公益助学金帮助的学生，在长大成人后，也能力所能及地去帮助三个人，将爱传递下去。'
            ]
          }
        },
        {
          id: 'faith',
          image: image3,
          activeImage: activeImage3,
          text: '我们的理念',
          details: {
            title: '我们的理念',
            pList: [
              '无论在家庭生活中，还是工作中，女性的力量往往容易被忽略。而在成长期，许多女生因为贫困及家庭观念无法像同龄的男生一样接受良好教育。「把爱传下去」的成立正是为了弥补这一缺憾。',
              '我们是由【北京微爱基金会】联合【一个村小】发起的公益助学项目，致力于小而精的帮助贫困地区的女学生在义务教育阶段结束后，继续完成高中、大学的学业。',
              '「把爱传下去」起于公益，但不止于公益，除了帮助学生完成学业之外，我们更希望借助公益的力量在学生心中埋下一颗爱与善的种子，在她们长大成人后，能够在力所能及的范围内惠及他人，将这份爱传递下去。为此，「把爱传出去」在为学生提供助学金的同时，建立【把爱传下去同学会】，积极与学生保持联络沟通，通过公开课、讲座、直播等形式，拓展学生视野，提高学生综合能力，鼓励学生在完成学业的同时，也能够成为一个拥有独立思想的人。',
              '我们始终坚信，无论是「把爱传出去」中的义工、资助者还是学生，我们都是非常渺小的个体，就像一个个漂泊不定的音符，但是当我们聚合在一起，却能够组成无限可能的音符，这样的音符多了，或许我们就可以奏出一首恢弘的交响，一首关于爱的交响。'
            ]
          }
        }
      ],
      selectedProgram: 'originator'
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="less">
.about {
  padding-top: 50px;
  .main-card {
    margin-bottom: 50px;
  }
  .originator {
    width: 224px;
    overflow: hidden;
    margin-bottom: 25px;
    > img {
      display: block;
      width: 100%;
      height: 234px;
    }
    &-name {
      height: 60px;
      margin-top: -20px;
      padding-top: 20px;
      line-height: 40px;
      text-align: center;
      color: #1D2130;
      background: rgba(0, 86, 199, 0.1);
      border-radius: 0 0 12px 12px;
    }
  }
}
@media (max-width: @sm-width) {
  .about {
    padding-top: 15px;
    .main-card {
      margin-bottom: 15px;
    }
    .originator {
      width: 112px;
      margin-bottom: 20px;
      > img {
        display: block;
        width: 100%;
        height: 117px;
      }
      &-name {
        height: 40px;
        margin-top: -20px;
        padding-top: 20px;
        line-height: 20px;
        text-align: center;
        background: rgba(0, 86, 199, 0.1);
        border-radius: 0 0 6px 6px;
        font-size: 12px;
      }
    }
  }
}
</style>
